import React, {Component} from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import Custom Components
import Breadcrumb from "../../common/breadcrumb";
import { getFeatureImages} from "../../../services";
import {photos} from '../../../api/portfolio'


class GridCols extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          isOpen: false,
          images: photos,
          columns:'col-sm-6',
          title:'Sarees'
        };
    }

    componentWillReceiveProps(nextProps) {
        const {columns} = nextProps.match.params;
       
            let result,title1;
            switch (columns) {
                case '7':   
                title1="Butta Mphoss";
                 break;
                case '6':   
                title1="M.Jaggad";
                 break;
                case '5':   
                title1="Tissue M.wait Jaggad";
                 break;
                case '4':   
                   title1="K.M.D slik";
                    break;
                case '3': 
                title1="Soft Slik";
                    break;
                case '2':  
                title1="Fancy Slik";
                    break;
                    case '1':  
                    title1="Wedding Sarees";
                    break;
                default:      
                    result = "col-sm-6";
                    break;

                 
            }
            result = "col-sm-6";
            this.setState({title:title1 });
        this.setState({columns: result });
   
    
    }

    selectImage = (index, type) => {
        this.setState({ 
            photoIndex: index,
            isOpen: true,
            images: type === 'all'?photos:getFeatureImages(photos, type) 
        })
    }

    render (){
            
        const { photoIndex, isOpen, images, columns,title } = this.state;
        return (
            <div>
                <Breadcrumb title={`${title}`} />

                {/* Our Project Start */}
                <section className="portfolio-section grid-portfolio ratio2_3 portfolio-padding">
                    <div className="container">
                     
                           
                               
                            
                           
                                <div className="row zoom-gallery">
                                    {photos.map((img, index) => 
                                        <div className={`isotopeSelector filter fashion ${columns}`} key={`all-${index}`}>
                                            <div className="overlay">
                                            <div className="border-portfolio">
                                            <a href="https://photos.app.goo.gl/pz6VcrL9fSlxekUu2" target="_blank" >      <div className="overlay-background" >
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                    
                                                    </div> </a>
                                                    <img src={img.src} className="img-fluid blur-up lazyload bg-img" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                          
                           
                            
                        
                    </div>
                </section>
                
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].src}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}
            </div>
        )
    }
}

export default GridCols