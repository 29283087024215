import React, {Component} from 'react';
import Slider from 'react-slick';
import {Helmet} from 'react-helmet';
import Instagram from "../../components/layouts/common/instagram";
import Breadcrumb from "../common/breadcrumb";
import {Slider2, Team4} from "../../services/script"
import {timeservice,
    svgFreeShipping,
    svgservice,
    svgoffer
} from "../../services/script"
import BlogSection from "../../components/layouts/common/blogsection";
class aboutUs extends Component {

    constructor (props) {
        super (props)

    }

    render (){

   


        return (
            <div>
                                        <Helmet>
                    <title> About Us - Kanchipuram Silk Sarees Manufacturers Wholesale Shop</title>
                    <meta name="description" content="Kanchipuram Sri Sarvalakshmi Silk Saree Manufacturers Wholsale Store is One of the Top Best Kanchipuram silk sarees Wholsale Shops in Kanchipuram.Buy sarees online at the leading kanchipuram Silk Saree Wholesale Store in kanchipuram
" />
                <meta name="keywords" content="Kanchipuram Sri Sarvalakshmi Silks,Kanchipuram Silk Sarees,Kanchipuram Silk Sarees Manufacturers,Kanchipuram Silk Sarees Manufacturers,Original silk saree shops in kanchipuram , Wholesale Shop,Kanchipuram Silk Sarees Wholesale Shop,kanchipuram Silk Saree Wholesale Store,kanchipuram sarees,Buy sarees online,leading kanchipuram Silk Saree Shop,Best Kanchipuram silk sarees Wholsale Shop,kanchipuram silk sarees wholesalers "></meta>
                </Helmet>
               
                <Breadcrumb title={'About Us'}/>
                {/*about section*/}
                <section >
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-12">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/about%20us.jpg`} className="img-fluid" alt=""/>
                                </div>
                            </div> */}
                            <div className="col-sm-12">
                            <div className="enqury">   <h4>Kancheepuram Sri Sarvalakshmi silk saree</h4></div>
                             <div className="about_p">

                              <br/> <p>A saree can just be a wear or the mark of your status.” Our Kanchipuram Silk Sarees wholesale shops has unmatched and rare collections from the top Silk sarees Production house of kanchipuram. We offer silk sarees to be viewed online and order online shopping. For buying from Kanchipuram Silk Saree Wholesalers best Collections, Bridal Sarees, pure kanchi pattu sarees for wedding, Indian Kanchi Silk Saris, anniversaries, festivals, parties, ceremonies and all other occasions, Contact us and get best prices and special offers. We have own hand loom place and well experienced weavers to build the best saree right from the starting stage Kanchipuram Sri Sarvalakshmi Wholesale silk sarees offers an unbelievable opportunity to get *Payback of 30% on real Silver Zari silk sarees off the original price of these Kancheepuram real silver zari sarees; we assure that you will repurchase Kanjivaram Sri Sarvalakshmi pure silver zari sarees with the 30% off.</p><p>We also avail worldwide express exporting to countries like India, United States, United Kingdom, Malaysia, Canada, United Arab Emirates, Australia, Singapore, Brazil, Sri Lanka, Russia, France, Germany, Saudi Arabia, Kuwait, Switzerland, Qatar, Netherlands, Oman and Bangladesh. we have regular customers around the world. Our embroidering and designing crafts team are so experienced and talented in replicating the designs from ancient buildings and carvings from mahabalipuram and cultural buildings of kanchipuram. Step in to get best kanchipuram sarees in kanchipuram at wholesale saree shops</p> <p>We have our own handloom factory and weavers to manufacture the 
                                    best silk sarees from the beginning. We also avail worldwide 
                                    express shipping facility to countries like India, United States,
                                    United Kingdom, Malaysia, Canada, United Arab Emirates, Australia, 
                                    Singapore, Brazil, Sri Lanka, Russia, France, Germany, Saudi Arabia,
                                     Kuwait, Switzerland, Qatar, Netherlands, Oman and Bangladesh. And
                                      we have regular customers from all over the world. The Artistic 
                                      sarees are made with patterns and designs that are used by ancient
                                      sculptors of India. Step into our popular wholesale exclusive kanchi 
                                      pattu sarees shop for Latest, Best and customized collection.</p>
                                      </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*service layout*/}
                <div className="container">
                    <section className="service border-section small-section ">
                        <div className="row">
                            <div className="col-md-3 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                    <div className="media-body">
                                        <h4> shipping</h4>
                                        <p> Free Shipping across India
International Shipping Available</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 service-block">
                                <div className="media">
                                <div dangerouslySetInnerHTML={{ __html: timeservice }} />
                                   
                                    <div className="media-body">
                                        <h4>shop time</h4>
                                        <p>Our Shop Opening Time All Days
7AM – 10PM.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                    <div className="media-body">
                                        <h4>24 X 7 service</h4>
                                        <p>online service for new customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 service-block">
                                <div className="media">
                                <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                    <div className="media-body">
                                        <h4>festival offer</h4>
                                        <p>new online special festival offer</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </section>
                </div>
                {/*Blog Section end*/}


                <section className=" contact-page ">
                {/* <div className="row">
                        <div className="col">


                    

                          
                        </div>
                    </div> */}
                
                    <div className="container">
                    <div className="title4"><h2 className="title-inner4">Wholesale Enquiry</h2><div className="line"><span></span></div></div>
                        <div className="row ">
                        
                            <div className="col-md-7 col-lg-7 ">
                            <iframe  src="https://docs.google.com/forms/d/e/1FAIpQLScFxbNRPM2FXnGZbDzGd08lG6P0ZBlxFDLQPVIRMR0LesLatw/viewform?embedded=true" width="100%" height="910px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                            </div>
                            <div className="col-lg-5 col-md-5">
                        <div className="uiScaledImageContainer">    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fkanchipuramsilkshop%2F&amp;tabs=timeline&amp;width=480&amp;height=910px&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="480"  height="600"  frameBorder="0"  allow="encrypted-media"></iframe>
                        </div>   <div  className="about_p">  <p>

                        We are manufacturers and Wholesale suppliers of Kanchipuram Wedding Silk Sarees, kanchi pattu sarees, Gift Sarees, Soft Silk Sarees at wholesale price. We provide attractive high quality products at competitive prices. We offer special discounts for Wedding and resale purchase.
                        </p></div>
                            </div>
                        </div>
                      


                     
                    </div>
                </section>







                 {/* <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1 section-t-space">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                            </div>
                        </div>
                    </div>
                </div>
               <section className="blog p-t-0">
                    <BlogSection />
                </section> */}
                {/*Blog Section End*/}

                {/* <Instagram /> */}

                {/*logo section*/}
              
                <section className="blog  p-t-0">
                <div className="title1 ">
                                
                                <h2 className="title-inner1">360 view Shop</h2>
                            </div>


                         
                        
                            <div className="col-lg-12 map">
                            <iframe src="https://www.google.com/maps/embed?pb=!4v1582612398665!6m8!1m7!1sCAoSK0FGMVFpcE8tN2wxU3VMTWZIR0NLSlJXamo3eEJRRWdDZHdQLVJVbFNjR3M.!2m2!1d12.82423858774373!2d79.70557157416397!3f352.2934568662749!4f-3.672174733751717!5f0.7820865974627469" width="100%" height="400px" frameBorder="0"  ></iframe>
                          
                            </div>
                </section>



                {/*logo section end*/}

                <section className="section-b-space">
    <div className="container">
<div className="title1 ">
                <h4>Customer Feedbacks</h4>
                <h2 className="title-inner1">TESTIMONIALS</h2>
            </div>
<BlogSection />
</div>
</section>
                {/*service layout end*/}

            </div>
        )
    }
}

export default aboutUs