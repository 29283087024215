import React, {Component} from 'react';

import { withTranslate } from 'react-redux-multilingual'

class TopBar extends Component {

    render() {
        const {translate} = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>{translate('topbar_title', { theme_name: ' Multikart' })}</li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>{translate('call_us')}: <a className="tel_a" href="tel:+91 9976328080">+91 9976328080</a></li>
                             
                                  
                             
                                </ul>
                            </div>
                        </div>
                        <div className="d-none d-sm-none d-md-none d-lg-block col-lg-6 text-right">
                            <ul className="header-dropdown">
                            <li><i className="fa fa-whatsapp" aria-hidden="true"></i>    <a href="https://api.whatsapp.com/send?phone=+919092780315&amp;text=Sareeprice%3F" target="_blank"> +91 9092780315</a></li> 
                          <li> <i className="fa fa-whatsapp" aria-hidden="true"></i> <a href="https://api.whatsapp.com/send?phone=+918610329465&amp;text=Sareeprice%3F" target="_blank">+91 8610329465</a>
                          </li></ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);