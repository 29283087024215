import React, { Component } from 'react';


import {Slider6} from "../../../services/script";

class LogoBlock extends Component {

    render (){
        return (
            <section className="section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                           
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default LogoBlock;