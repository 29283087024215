import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {Helmet} from 'react-helmet';
import BlogSection from "../../components/layouts/common/blogsection";
import Instagram from "../../components/layouts/common/instagram";

class Contact extends Component {

    constructor (props) {
        super (props)
    }

    render (){


        return (
            <div>
                       <Helmet>
                    <title>Contact Us Sri Sarvalakshmi Silks - Kanchipuram Silk Sarees Manufacturers Wholesale Shop</title>
                    <meta name="description" content="Sri Sarva lakshmi Silk Saree Manufacturers Wholsale Store is One of the Top Best Kanchipuram silk sarees Wholsale Shops in Kanchipuram.Buy sarees online at the leading kanchipuram Silk Saree Wholesale Store in kanchipuram
" />
                <meta name="keywords" content="Sri Sarva lakshmi Silks,Kanchipuram Silk Sarees,Kanchipuram Silk Sarees Manufacturers,Kanchipuram Silk Sarees Manufacturers,Original silk saree shops in kanchipuram , Wholesale Shop,Kanchipuram Silk Sarees Wholesale Shop,kanchipuram Silk Saree Wholesale Store,kanchipuram sarees,Buy sarees online,leading kanchipuram Silk Saree Shop,Best Kanchipuram silk sarees Wholsale Shop,kanchipuram silk sarees wholesalers "></meta>
                </Helmet>
                <Breadcrumb title={'Contact Us'}/>
                
                
                {/*Forget Password section*/}
             
                {/*logo section end*/}
     <section className=" contact-page section-b-space">
                    <div className="container">
                        <div className="row ">
                        
                            <div className="col-lg-7 map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15561.404770576513!2d79.716519!3d12.820568!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1664e25fd8ba68dd!2sSri+Sarvalakshmi+Silk+Sarees+Manufacturer+Wholesale+Supplier!5e0!3m2!1sen!2sus!4v1531218995419" ></iframe>
                            </div>
                            <div className="col-lg-5">
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="Generic placeholder image" />
                                                    <h6>Contact Us</h6>
                                            </div>
                                            <div className="media-body">
                                            <p><a className="tel_a" href="tel:+91 86103 29465">+91 86103 29465</a></p>
                              <p> <a className="tel_a" href="tel:+91 90927 80315">+91 90927 80315</a></p>
                                <p> <a className="tel_a" href="tel:+91 9976328080">+91 99763 28080</a></p>
                                <p> <a className="tel_a" href="tel:+91 70926 61234">+91 70926 61234</a></p>
                             
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                <h6>Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>
                                                    
Sri SarvaLakshmi Silk Sarees<br></br>
No. 97, Vilakadi Koil Street
Kanchipuram - 631501
(Near Kirai Mandabam)
Tamil Nadu, India</p>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                                                    <h6>Shop Time</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>Our Shop Opening Time All Days<br></br> 
                                                7AM – 10PM.</p>
                                               
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`} alt="Generic placeholder image" />
                                                    <h6>Mail</h6>
                                            </div>
                                            <div className="media-body">
                                                <p><a href="mailto:kanchipurampattu@gmail.com">kanchipurampattu@gmail.com</a></p>
                                               
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                      


                     
                    </div>
                </section>

                <section className=" contact-page ">
                {/* <div className="row">
                        <div className="col">


                    

                          
                        </div>
                    </div> */}
                
                    <div className="container">
                    <div className="title4"><h2 className="title-inner4">Wholesale Enquiry</h2><div className="line"><span></span></div></div>
                        <div className="row ">
                        
                            <div className="col-md-7 col-lg-7 ">
                            <iframe  src="https://docs.google.com/forms/d/e/1FAIpQLScFxbNRPM2FXnGZbDzGd08lG6P0ZBlxFDLQPVIRMR0LesLatw/viewform?embedded=true" width="100%" height="910px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                            </div>
                            <div className="col-lg-5 col-md-5">
                        <div className="uiScaledImageContainer">    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fkanchipuramsilkshop%2F&amp;tabs=timeline&amp;width=400&amp;height=910px&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="480"  height="600"  frameBorder="0"  allow="encrypted-media"></iframe>
                        </div>   <div  className="about_p">  <p>

                        We are manufacturers and Wholesale suppliers of Kanchipuram Wedding Silk Sarees, kanchi pattu sarees, Gift Sarees, Soft Silk Sarees at wholesale price. We provide attractive high quality products at competitive prices. We offer special discounts for Wedding and resale purchase.
                        </p></div>
                            </div>
                        </div>
                      


                     
                    </div>
                </section>

                {/* <Instagram /> */}

{/*logo section*/}

<section className="blog  p-t-0">
<div className="title1 ">
                
                <h2 className="title-inner1">360 view Shop</h2>
            </div>


         
        
            <div className="col-lg-12 map">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1582612398665!6m8!1m7!1sCAoSK0FGMVFpcE8tN2wxU3VMTWZIR0NLSlJXamo3eEJRRWdDZHdQLVJVbFNjR3M.!2m2!1d12.82423858774373!2d79.70557157416397!3f352.2934568662749!4f-3.672174733751717!5f0.7820865974627469" width="100%" height="400px" frameBorder="0"  ></iframe>
          
            </div>
</section>



{/*logo section end*/}

<section className="section-b-space">
    <div className="container">
<div className="title1 ">
                <h4>Customer Feedbacks</h4>
                <h2 className="title-inner1">TESTIMONIALS</h2>
            </div>
<BlogSection />
</div>
</section>










             {/*   <section className="portfolio-section grid-portfolio ratio2_3 portfolio-padding">
                    <div className="container">

                    <div className="row zoom-gallery">
                                    {photos.map((img, index) => 
                                        <div className={`isotopeSelector filter fashion col-sm-6`} key={`all-${index}`}>
                                            <div className="overlay">
                                                <div className="border-portfolio">
                                                    <div className="overlay-background"  onClick={() => this.selectImage(index, 'all')}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </div>
                                                    <img src={img.src} className="img-fluid blur-up lazyload bg-img" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                        </div>
                        </section>




                 <section className="ratio_asos section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="no-slider row">
                                <div className="product-box">
                <div className="img-wrapper">
                    <div className="lable-block">
                      
                    </div>
                    <div className="front">
                        <a href="#" ><img
                            src={`${process.env.PUBLIC_URL}/static/media/1.dc58b131.jpg`}
                            className="img-fluid blur-up lazyload bg-img"
                            alt="" /></a>
                    </div>
                    
                 

                </div>
              
            </div>







                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                

            </div>
        )
    }
}

export default Contact