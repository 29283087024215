import React, { Component } from 'react';

import {Helmet} from 'react-helmet'
import '../components/common/index.scss';
// Import custom components 
import TopCollection from '../components/layouts/fashion/top-collection';
import SpecialProducts from "../components/layouts/common/products";
import BlogSection from "../components/layouts/common/blogsection";

import { Carousel } from 'react-responsive-carousel';
import {timeservice,
    svgFreeShipping,
    svgservice,
    svgoffer
} from "../services/script"
import './landing.scss';
class Landing extends Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );
    }

	render() {
		return (
			<div>
                          <Helmet>
                    <title> Kanchipuram Silk Sarees Wholesale Shop Online Supplier  </title>
                    <meta name="description" content="Sri Sarvalakshmi Silks Kanchipuram Silk Sarees Manufacturers and Wholsale Shop Buy Kancheepuram Silk Sarees wholesale price We are leading kanchipuram silk saree wholesale dealers in kanchipuram
" />
                <meta name="keywords" content="Sri Sarvalakshmi Silks,Kanchipuram Silk Sarees,Kanchipuram Silk Sarees Manufacturers,Kanchipuram Silk Sarees Manufacturers,Original silk saree shops in kanchipuram , Wholesale Shop,Kanchipuram Silk Sarees Wholesale Shop,kanchipuram Silk Saree Wholesale Store,kanchipuram sarees,Buy sarees online,leading kanchipuram Silk Saree Shop,Best Kanchipuram silk sarees Wholsale Shop,kanchipuram silk sarees wholesalers"></meta>
                </Helmet>
         
                {/*Home Slider*/}
                <section className="p-0">
                <Carousel showThumbs={false}>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home-banner/slider-3.jpg`} alt="kanchipuram silk sarees wholesalers "/>
                   
                </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home-banner/banner-1.jpg`} alt="Best Kanchipuram silk sarees Wholsale Shop"/>
                 
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home-banner/banner-2.jpg`} alt="Buy sarees online"/>
                   
                </div>
               
                
                
                      </Carousel>
                </section>
                {/*Home Section End*/}
                <a href="https://api.whatsapp.com/send?phone=+91 9976328080&amp;text=Hai" className="flot" target="_blank">
<i className="fa fa-whatsapp my-float"></i>
</a>


<section>

<div className="title4"><h5 className="title-inner4">Kanchipuram Sri Sarvalakshmi Silks Manufacturer And Wholesaler</h5><div className="line"><span></span></div></div>
<div className="title1 ">
                               
                               
                               </div>
              

<div className="container">

                        <div className="row ">
                            <div className="col-md-6">
                          
<div className="about_p">

    <h5 className="title-inner4">A good silk saree is the one that equally integrates Gorgeous look and Comfort to wear.</h5>
     <p >       
  We use superior quality of silk and specialised colouring agents for our silk sarees.We Are Kanchipuram Silk Sarees Wholesale Shop Online Supplier.Our sarees are made with the best method of weaving by weavers with decades of experience. Weavers who are capable of making each of their saree, a masterpiece. The designs and patterns from our designers are collected with Tamizh tradition as their background.The Artistic sarees are made with patterns and designs that are used by ancient sculptors of india.We Provide pure silk sarees At Wholesale Price.Our Famous Silk Sarees are Best in market Price, comfortable and designed with latest style and trend. Sarees Quality and rate are always unmatched by other Silk Saree show rooms in kanchipuram.We are leading kanchipuram silk saree wholesale dealers in kanchipuram. </p>             

<p> Make call or WhatsApp for Enquiry :<br></br>
  <a href="tel:+91 86103 29465">+91 86103 29465 </a>||<a href="tel:+91 90927 80315 "> +91 90927 80315 </a>
{/* More details original kanchipuram Silk sarees Please contact
<a href="https://www.kanchipurampattu.com/"> https://www.kanchipurampattu.com/    </a> */}

If you want Wholesale Kanchipuram Silk Saree Please come to our Shop Directly
Google Map Link
<a href="https://goo.gl/maps/iu6tzVVzSLp">https://goo.gl/maps/iu6tzVVzSLp</a></p>

</div>







                                </div>
                                <div className="col-md-6">
                                  
                                        
<img src={`${process.env.PUBLIC_URL}/assets/images/about/about.jpg`} width='100%' alt="kanchipuram Silk Saree Wholesale Store"/>
                               



                                    </div>



                                </div>



                                </div>


</section>


  


                <section className="pb-0" >
                    <div className="container">
 
                                                             
<div className="title4"><h2 className="title-inner4">Kanchipuram Silk Wholesale </h2><div className="line"><span></span></div></div>
                        <div className="row">
                        <div className="about_p">


<p className="container">  
Kanchipuram Sri Sarvalakshmi Silk Saree Manufacturers Wholsale Store is One of the Top Best Kanchipuram silk sarees Wholsale Shops in Kanchipuram. For decades, It is proven to be a shopper's delight in Wedding Sarees, Bridal Sarees, designer kanchipuram silk sarees, soft silk sarees, Traditional kanchipuram sarees and kanjivaram silk sarees for all special occasions and festivals. Our Famous Silk Sarees are Best in market Price, comfortable and designed with latest style and trend.


. kanchipuram Sri Sarvalakshmi silks sarees offers original silk sarees at Best Price. We are the wholesale dealers and silk saree manufacturers in kanchipuram.You Can Buy Silk Sarees online By our Website.  </p>             


</div>
                        <div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 10500 - ₹ 25000 " target="_blank">               <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/wedding_saree.jpg`} className="img-fluid" alt="Kanchipuram Silk Sarees Manufacturers and Wholsale Shop"/>
       </a>             <h3>₹ 10500 - ₹ 25000</h3>
                    </div>
</div>
<div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 7500 - ₹ 12500 " target="_blank">              <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/fancy_saree.jpg`} className="img-fluid" alt="Buy Kancheepuram Silk Sarees"/>
             </a>       <h3>₹ 7500 - ₹ 12500</h3>
                    </div>
</div>
<div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 5000 - ₹ 7000 " target="_blank">             <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/soft_silk.jpg`} className="img-fluid" alt="Kancheepuram Silk Sarees wholesale price "/>
           </a>         <h3>₹ 5000 - ₹ 7000</h3>
                    </div>
</div>
<div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 3500  - ₹ 4500 " target="_blank">         <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/K.M.D-Soft-Pure-Silk-sarees.jpg`} className="img-fluid" alt="leading kanchipuram silk saree"/>
              </a>      <h3>₹ 3500  - ₹ 4500 </h3>
                    </div>
</div>
<div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 3200 - ₹ 5500 " target="_blank">         <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/Tissue-M.-wait-Jaggad-Saree.jpg`} className="img-fluid" alt="kanchipuram silk saree wholesale dealers in kanchipuram"/>
              </a>      <h3>₹ 3200 - ₹ 5500</h3>
                    </div>
</div>
<div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 3200 - ₹ 5500 " target="_blank">         <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/mjaggad.jpg`} className="img-fluid" alt="Original silk saree shops in kanchipuram"/>
              </a>      <h3>₹ 2200  - ₹ 3000 </h3>
                    </div>
</div>

<div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 1300 - ₹ 1700 " target="_blank">      
  <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/Butta-Mphoss-Sarees.jpg`} className="img-fluid" alt="Kanchipuram Silk Sarees Wholesale Shop"/>
                 </a>   <h3>₹ 1300 - ₹ 1700</h3>
                    </div>

</div>
{/* <div className="col-md-3">
<div className="image_price">
<a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 1100 - ₹ 1400 " target="_blank">
 <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/Plain-Mphoss.-Sarees.-art-silks.R.P.jpg`} className="img-fluid" alt=""/>
</a>
                    <h3>₹ 1100 - ₹ 1400</h3>
                    </div>
</div> */}
                        <div className="col-md-3">
                        <div className="image_price">

                            
                 <a href="https://api.whatsapp.com/send?phone=+91 9976328080
&amp;text=Hai I Need Saree in the Range of ₹ 1000 - ₹ 1300 " target="_blank">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/fashion/product/Plain-Mphoss-Sarees.-art-silks..D.P.jpg`} className="img-fluid" alt="kanchipuram Silk Saree Wholesale Store"/></a>  
                    <h3>₹ 1000 - ₹ 1300</h3>
                    </div>
                           
</div>






















                    </div>



                    </div>
                </section>
                {/*collection banner end*/}

                <TopCollection type={'women'} />

                {/*Parallax banner*/}
                <section className="p-0">
                    <div className="full-banner parallax-banner1 parallax text-center p-left">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className=" banner-contain">
                                        <h2  className="cw ">2020</h2>
                                        <h3 className="cw ">fashion trends</h3>
                                        <h4 className="cw ">special offer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Parallax banner End*/}

                <SpecialProducts />

                {/*service layout*/}
                <div className="container">
                    <section className="service border-section small-section ">
                        <div className="row">
                            <div className="col-md-3 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                    <div className="media-body">
                                        <h4> shipping</h4>
                                        <p> We Are Shipping Kanchipuram Silk Sarees All Over The World.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 service-block">
                                <div className="media">
                                <div dangerouslySetInnerHTML={{ __html: timeservice }} />
                                   
                                    <div className="media-body">
                                        <h4>shop time</h4>
                                        <p>Our Shop Opening Time All Days
7AM – 10PM.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                    <div className="media-body">
                                        <h4>24 X 7 service</h4>
                                        <p>online service for new customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 service-block">
                                <div className="media">
                                <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                    <div className="media-body">
                                        <h4>festival offer</h4>
                                        <p>new online special festival offer</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </section>
                </div>
                {/*Blog Section end*/}


                <section className=" contact-page ">
                {/* <div className="row">
                        <div className="col">


                    

                          
                        </div>
                    </div> */}
                
                    <div className="container">
                    <div className="title4"><h2 className="title-inner4">kanchipuram Silk Wholesale Enquiry</h2><div className="line"><span></span></div></div>
                    <div  className="about_p"> <p >We Are kanchipuram silk sarees wholesaler and Supplier.kanjipuram pattu sarees expresses the traditional value of your Tamil culture.You are in the right page to buy silk saree wholesale online at the real price.We Are Best Kanchipuram silk sarees Wholsale Shop in Kanchipuram .</p></div><br></br>
                        <div className="row ">
                        
                            <div className="col-md-7 col-lg-7 ">
                            <iframe  src="https://docs.google.com/forms/d/e/1FAIpQLScFxbNRPM2FXnGZbDzGd08lG6P0ZBlxFDLQPVIRMR0LesLatw/viewform?embedded=true" width="100%" height="910px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                            </div>
                            <div className="col-lg-5 col-md-5">
                        <div className="uiScaledImageContainer">    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fkanchipuramsilkshop%2F&amp;tabs=timeline&amp;width=400&amp;height=910px&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="480"  height="600"  frameBorder="0"  allow="encrypted-media"></iframe>
                        </div>   <div  className="about_p">  <p>

                        We are manufacturers and Wholesale suppliers of Kanchipuram Wedding Silk Sarees, Fancy Silk Sarees, Semi Silk Sarees, Gift Sarees, Soft Silk Sarees at wholesale price. We provide attractive high quality products at competitive prices. We offer special discounts for Wedding and resale purchase.
                        </p></div>
                            </div>
                        </div>
                      


                     
                    </div>
                </section>







                 {/* <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1 section-t-space">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                            </div>
                        </div>
                    </div>
                </div>
               <section className="blog p-t-0">
                    <BlogSection />
                </section> */}
                {/*Blog Section End*/}

                {/* <Instagram /> */}

                {/*logo section*/}
              
                <section className="blog  p-t-0">
                <div className="title1 ">
                                
                                <h2 className="title-inner1">360 view Shop</h2>
                            </div>


                         
                        
                            <div className="col-lg-12 map">
                            <iframe src="https://www.google.com/maps/embed?pb=!4v1582612398665!6m8!1m7!1sCAoSK0FGMVFpcE8tN2wxU3VMTWZIR0NLSlJXamo3eEJRRWdDZHdQLVJVbFNjR3M.!2m2!1d12.82423858774373!2d79.70557157416397!3f352.2934568662749!4f-3.672174733751717!5f0.7820865974627469" width="100%" height="400px" frameBorder="0"  ></iframe>
                          
                            </div>
                </section>



                {/*logo section end*/}

<section>
                <div className="title1 ">
                                <h4>Customer Feedbacks</h4>
                                <h2 className="title-inner1">TESTIMONIALS</h2>
                            </div>
<BlogSection />
</section>

                <section className=" contact-page section-b-space">
                    <div className="container">
                        <div className="row section-b-space">
                        
                            <div className="col-lg-7 map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15561.404770576513!2d79.716519!3d12.820568!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1664e25fd8ba68dd!2sSri+Sarvalakshmi+Silk+Sarees+Manufacturer+Wholesale+Supplier!5e0!3m2!1sen!2sus!4v1531218995419" ></iframe>
                            </div>
                            <div className="col-lg-5">
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="kanchipuram sarees" />
                                                    <h6>Contact Us</h6>
                                            </div>
                                            <div className="media-body">
                                            <p> <a className="tel_a" href="tel:+91 86103 29465">+91 86103 29465</a></p> 
                              <p> <a className="tel_a" href="tel:+91 90927 80315">+91 90927 80315</a></p>
                                <p> <a className="tel_a" href="tel:+91 9976328080">+91 99763 28080</a></p>
                                <p> <a className="tel_a" href="tel:+91 70926 61234">+91 70926 61234</a></p>
                     
                                              
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                <h6>Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>
                                                    
Sri SarvaLakshmi Silk Sarees<br></br>
No. 97, Vilakadi Koil Street
Kanchipuram - 631501
(Near Desikar Temple)
Tamil Nadu, India</p>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                                                    <h6>Shop Time</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>Our Shop Opening Time All Days<br></br> 
                                                7AM – 10PM.</p>
                                               
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`} alt="Buy sarees online" />
                                                    <h6>Mail</h6>
                                            </div>
                                            <div className="media-body">
                                            <p><a href="mailto:kanchipurampattu@gmail.com">kanchipurampattu@gmail.com</a></p>
                                               
                                            </div>
                                        </li>
                                      
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                      


                    
                    </div>
                </section>








			</div>
			)


	}
}

export default Landing;