import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import { Carousel } from 'react-responsive-carousel';
import {Link} from 'react-router-dom';

// Import custom components
import TopCollection from './top-collection';
import SpecialProducts from "../common/products";
import BlogSection from "../common/blogsection";
import Instagram from "../common/instagram";
import LogoBlock from "../common/logo-block";
import {
    svgFreeShipping,
    svgservice,
    svgoffer
} from "../../../services/script"


class Fashion extends Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );
    }

	render() {
		return (
			<div>
                              <Helmet>
                    <title> Kanchipuram Silk Sarees Wholesale Shop Online Supplier  </title>
                    <meta name="description" content="Sri Sarva lakshmi Silks Kanchipuram Silk Sarees Manufacturers and Wholsale Shop Buy Kancheepuram Silk Sarees wholesale price We are leading kanchipuram silk saree wholesale dealers in kanchipuram
" />
                <meta name="keywords" content="Sri Sarva lakshmi Silks,Kanchipuram Silk Sarees,Kanchipuram Silk Sarees Manufacturers,Kanchipuram Silk Sarees Manufacturers,Original silk saree shops in kanchipuram , Wholesale Shop,Kanchipuram Silk Sarees Wholesale Shop,kanchipuram Silk Saree Wholesale Store,kanchipuram sarees,Buy sarees online,leading kanchipuram Silk Saree Shop,Best Kanchipuram silk sarees Wholsale Shop,kanchipuram silk sarees wholesalers"></meta>
                </Helmet>
                {/*Home Slider*/}
                <section className="p-0">
                <Carousel showThumbs={false}>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home-banner/banner-1.jpg`} />
                 
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home-banner/banner-2.jpg`} />
                   
                </div>
               
                
                      </Carousel>
                </section>
                {/*Home Section End*/}
                <a href="https://api.whatsapp.com/send?phone=+7845124763&amp;text=Hai" className="flot" target="_blank">
<i className="fa fa-whatsapp my-float"></i>
</a>


<section>

<div class="title4"><h2 class="title-inner4">Sri Sarvalakshmi Silk Sarees Wholesale</h2><div class="line"><span></span></div></div>
<div className="title1 ">
                               
                               
                               </div>
              

<div className="container">

                        <div className="row ">
                            <div className="col-md-6">
                          
<div className="about_p">
     <p>       
“A Saree can be a simple cloth or a symbol of honour”. Our Sri Sarvalakshmi Silk Sarees wholesale shop has unique and exclusive collection from The Best in Class Silk sarees Production. We also offer superior branded Original silk sarees to purchase online. If you need Kanchipuram Silk Saree Collections, Pattu sarees, Soft Silk Sarees, Pure Wedding silk Sarees, kanjipuram pattu, Indian Kanchi Silk Sarees,south silk sarees, Bridal Kancheepuram Sarees buy latest pure kanchipuram pattu sarees for wedding, anniversaries, festivals, parties, ceremonies, etc. Contact us to get unbeatable prices and discounts.</p>             

<p>

We have our own handloom factory and weavers to manufacture the best silk sarees from the beginning. We also avail worldwide express shipping facility to countries like India, United States, United Kingdom, Malaysia, Canada, United Arab Emirates, Australia, Singapore, Brazil, Sri Lanka, Russia, France, Germany, Saudi Arabia, Kuwait, Switzerland, Qatar, Netherlands, Oman and Bangladesh. And we have regular customers from all over the world. The Artistic sarees are made with patterns and designs that are used by ancient sculptors of India. Step into our popular wholesale exclusive kanchi pattu sarees shop for Latest, Best and customized collection.</p>

</div>







                                </div>
                                <div className="col-md-6">
                                  
                                        
<img src={`${process.env.PUBLIC_URL}/assets/images/about/about.jpg`} width='100%' />
                               



                                    </div>



                                </div>



                                </div>


</section>


                {/*collection banner*/}
                <section className="pb-0" id="shop">
                    <div className="container">
                        <div className="row partition2">
                            <div className="col-md-6">
                                <a href="#">
                                    <div className="collection-banner p-right text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/sub-banner1.jpg`} className="img-fluid" alt=""/>
                                            <div className="contain-banner">
                                                <div>
                                                    <h4 className="cw ">save 30%</h4>
                                                    <h2 className="cw ">Silk Sarees</h2>
                                                </div>
                                            </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href="#">
                                    <div className="collection-banner p-right text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/sub-banner2.jpg`} className="img-fluid" alt=""/>
                                            <div className="contain-banner">
                                                <div>
                                                    <h4 className="cw ">save 60%</h4>
                                                    <h2 className="cw ">Wedding Silks</h2>
                                                </div>
                                            </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/*collection banner end*/}

                <TopCollection type={'women'} />

                {/*Parallax banner*/}
                <section className="p-0">
                    <div className="full-banner parallax-banner1 parallax text-center p-left">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className=" banner-contain">
                                        <h2  className="cw ">2020</h2>
                                        <h3 className="cw ">fashion trends</h3>
                                        <h4 className="cw ">special offer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Parallax banner End*/}

                <SpecialProducts />

                {/*service layout*/}
                <div className="container">
                    <section className="service border-section small-section ">
                        <div className="row">
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                    <div className="media-body">
                                        <h4> shipping</h4>
                                        <p> shipping world wide</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                    <div className="media-body">
                                        <h4>24 X 7 service</h4>
                                        <p>online service for new customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                    <div className="media-body">
                                        <h4>festival offer</h4>
                                        <p>new online special festival offer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*Blog Section end*/}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1 section-t-space">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="blog p-t-0">
                    <BlogSection />
                </section>
                {/*Blog Section End*/}

                <Instagram />

                {/*logo section*/}
              
                <section className="blog p-t-0">
                <div className="title1 ">
                                
                                <h2 className="title-inner1">360 view Shop</h2>
                            </div>


                         
                        
                            <div className="col-lg-12 map">
                            <iframe src="https://www.google.com/maps/embed?pb=!4v1579500930592!6m8!1m7!1sCAoSK0FGMVFpcE5wcm9sS0NfUFE3dEFvTnFjMlN4MGZrRTdCcTNLRWJXUlhrZG8.!2m2!1d12.82415799022936!2d79.70557212944777!3f229.70562716305363!4f16.341017211979562!5f0.4000000000000002" width="100%" height="300px" frameBorder="0"  ></iframe>
                          
                            </div>
                </section>



                {/*logo section end*/}





                <section className=" contact-page section-b-space">
                    <div className="container">
                        <div className="row section-b-space">
                        
                            <div className="col-lg-7 map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15561.404770576513!2d79.716519!3d12.820568!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1664e25fd8ba68dd!2sSri+Sarvalakshmi+Silk+Sarees+Manufacturer+Wholesale+Supplier!5e0!3m2!1sen!2sus!4v1531218995419" ></iframe>
                            </div>
                            <div className="col-lg-5">
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="Generic placeholder image" />
                                                    <h6>Contact Us</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>+91 90927 80315</p>
                                                <p>+91 86103 29465</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                <h6>Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>No. 97, Vilakadi Koil Street
Kanchipuram - 631501
(Near Desikar Temple)
Tamil Nadu, India</p>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`} alt="Generic placeholder image" />
                                                    <h6>Mail</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>kanchipurampattu@gmail.com</p>
                                               
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                      


                        <iframe  src="https://docs.google.com/forms/d/e/1FAIpQLScFxbNRPM2FXnGZbDzGd08lG6P0ZBlxFDLQPVIRMR0LesLatw/viewform?embedded=true" width="100%" height="840px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                    </div>
                </section>








			</div>
			)


	}
}

export default Fashion;