import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations'
import { getAllProducts } from './actions'
import Landing from './components/landing'


// Layouts
import Fashion from './components/layouts/fashion/main';



//Collection Pages





// Product Pages

import RightSideBar from "./components/products/right-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import LeftImage from "./components/products/left-image";
import RightImage from "./components/products/right-image";
import Accordian from "./components/products/accordian";
import ColumnLeft from "./components/products/column-left";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import Vertical from "./components/products/vertical";

// Features
import Layout from './components/app'




// Extra Pages
import aboutUs from './components/pages/about-us'
import PageNotFound from './components/pages/404'
import lookbook from './components/pages/lookbook'
import Login from './components/pages/login'
import Register from './components/pages/register'
import Search from './components/pages/search'
import Collection from './components/pages/collection'
import ForgetPassword from './components/pages/forget-password'
import Contact from './components/pages/contact'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq'
import Enquiry from './components/pages/enquiry'
// Blog Pages




// Theme Element
import ElementTitle from "./components/features/theme/element-title"
import ElementBanner from "./components/features/theme/element-banner";
import ElementSlider from "./components/features/theme/element-slider";
import ElementCategory from "./components/features/theme/element-category";
import ElementService from "./components/features/theme/element-service";
import ElementRatio from "./components/features/theme/element-ratio";

// Product Elements
import ElementProductBox from "./components/features/product/element-product-box"
import ElementProductSlider from "./components/features/product/element-product-slider"
import ElementProductNoSlider from "./components/features/product/element-product-no-slider"
import ElementMultipleSlider from "./components/features/product/element-multiple-slider"
import ElementProductTab from "./components/features/product/element-product-tab"

// Portfolio Features
import GridCols from "./components/features/portfolio/grid-cols"
import MasonaryGridCols from "./components/features/portfolio/masonary-grid-cols"


import Wedding from "./components/features/portfolio/wedding"
import Soft from "./components/features/portfolio/soft"
import Fancy from "./components/features/portfolio/fancy"
import Semi from "./components/features/portfolio/semi"   

import jaggad_sarees from "./components/features/portfolio/jaggad_sarees"
import butta_mphoss_sarees from "./components/features/portfolio/butta_mphoss_sarees"
import kmd_silksarees from "./components/features/portfolio/kmd_silksarees"
import plain_mphoss_sarees_art_silks_rp from "./components/features/portfolio/plain_mphoss_sarees_art_silks_rp"
import plain_mphoss_sarees_art_silks_dp from "./components/features/portfolio/plain_mphoss_sarees_art_silks_dp"
import pure_soft_silks_sarees from "./components/features/portfolio/pure_soft_silks_sarees"
import tissue_m_wait_Jaggad_sarees from "./components/features/portfolio/tissue_m_wait_Jaggad_sarees"
import kanchipuram_pure_fancy_silk_sarees from "./components/features/portfolio/kanchipuram_pure_fancy_silk_sarees"
import Gift from "./components/features/portfolio/gift"
import kanchipuram_pure_wedding_silks_sarees from "./components/features/portfolio/kanchipuram_pure_wedding_silks_sarees"
class Root extends React.Component {

    render() {
        store.dispatch(getAllProducts());

        return(
        	<Provider store={store}>
                <IntlProvider translations={translations} locale='en'>
				<BrowserRouter basename={'/'} >
					<ScrollContext>
						<Switch>
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing}/> */}
                           
                            <Layout>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing}/>
                                {/*Routes For Layouts*/}
                                <Route path={`${process.env.PUBLIC_URL}/fashion`} component={Landing}/>

								{/*Routes For Features (Product Collection) */}

								
							
							
								

								{/*Routes For Single Product*/}
							
								<Route path={`${process.env.PUBLIC_URL}/right-sidebar/product/:id`} component={RightSideBar}/>
								<Route path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`} component={NoSideBar}/>
								<Route path={`${process.env.PUBLIC_URL}/col-left/product/:id`} component={ColumnLeft}/>
								<Route path={`${process.env.PUBLIC_URL}/col-right/product/:id`} component={ColumnRight}/>
								<Route path={`${process.env.PUBLIC_URL}/accordian/product/:id`} component={Accordian}/>
								<Route path={`${process.env.PUBLIC_URL}/column/product/:id`} component={Column}/>
								<Route path={`${process.env.PUBLIC_URL}/left-image/product/:id`} component={LeftImage}/>
								<Route path={`${process.env.PUBLIC_URL}/right-image/product/:id`} component={RightImage}/>
								<Route path={`${process.env.PUBLIC_URL}/vertical/product/:id`} component={Vertical}/>
								

							

								<Route path={`${process.env.PUBLIC_URL}/sales/orders`} component={aboutUs}/>

								{/*Routes For Extra Pages*/}
                                <Route path={`${process.env.PUBLIC_URL}/pages/about-us`} component={aboutUs}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/404`} component={PageNotFound}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/kanchipuram_silk_sarees_wholesale_enquiry`} component={Enquiry}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/lookbook`} component={lookbook}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/register`} component={Register}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/search`} component={Search}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/collection`} component={Collection}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/contact`} component={Contact}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/dashboard`} component={Dashboard}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}/>

								{/*Features*/}
								{/*Theme Elements*/}
                                <Route path={`${process.env.PUBLIC_URL}/features/element-title`} component={ElementTitle}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-banner`} component={ElementBanner}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-slider`} component={ElementSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-category`} component={ElementCategory}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-service`} component={ElementService}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-ratio`} component={ElementRatio}/>

								{/*Product Elements*/}
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-box`} component={ElementProductBox}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-slider`} component={ElementProductSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-no-slider`} component={ElementProductNoSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-multiple-slider`} component={ElementMultipleSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-tab`} component={ElementProductTab}/>

								{/*Portfolios*/}

                                <Route path={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_wedding_sarees`} component={Wedding}/>
                                <Route path={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_fancy_sarees`} component={Fancy}/>
                                <Route path={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_soft_sarees`} component={Soft}/>
                                <Route path={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_semi_silk`} component={Semi}/>
         
                                  <Route path={`${process.env.PUBLIC_URL}/kanchipuram_pure_wedding_silks_sarees`} component={kanchipuram_pure_wedding_silks_sarees}/>
                <Route path={`${process.env.PUBLIC_URL}/butta_mphoss_sarees`} component={butta_mphoss_sarees}/> 
            <Route path={`${process.env.PUBLIC_URL}/kmd_silksarees`} component={kmd_silksarees}/> 
            <Route path={`${process.env.PUBLIC_URL}/plain_mphoss_sarees_art_silks_rp`} component={plain_mphoss_sarees_art_silks_rp}/> 
            <Route path={`${process.env.PUBLIC_URL}/plain_mphoss_sarees_art_silks_dp`} component={plain_mphoss_sarees_art_silks_dp}/> 
            <Route path={`${process.env.PUBLIC_URL}/pure_soft_silks_sarees`} component={pure_soft_silks_sarees}/> 
            <Route path={`${process.env.PUBLIC_URL}/tissue_m_wait_Jaggad_sarees`} component={tissue_m_wait_Jaggad_sarees}/> 
        <Route path={`${process.env.PUBLIC_URL}/jaggad_sarees`} component={jaggad_sarees}/>
                                <Route path={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_Gift_sarees`} component={Gift}/>

  <Route path={`${process.env.PUBLIC_URL}/kanchipuram_pure_fancy_silk_sarees`} component={kanchipuram_pure_fancy_silk_sarees}/>


                                <Route path={`${process.env.PUBLIC_URL}/features/portfolio-grid/:columns`} component={GridCols}/>
                               
                                <Route path={`${process.env.PUBLIC_URL}/features/portfolio-masonary/:columns`} component={MasonaryGridCols}/>

								{/*Blog Pages*/}
                              
                            
                            

                                {/* <Route exact path="*" component={PageNotFound} /> */}
                            </Layout>
                         </Switch>
					  </ScrollContext>
					</BrowserRouter>
                </IntlProvider>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


