import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {Helmet} from 'react-helmet';
class Enquiry extends Component {

    constructor (props) {
        super (props)
    }

    render (){


        return (
            <div>
           
                <Helmet>
                    <title>Wholesale Enquiry Sri Sarva lakshmi Silks - Kanchipuram Silk Sarees Manufacturers Wholesale Shop</title>
                    <meta name="description" content="Sri Sarva lakshmi Silk Saree Manufacturers Wholsale Store is One of the Top Best Kanchipuram silk sarees Wholsale Shops in Kanchipuram.Buy sarees online at the leading kanchipuram Silk Saree Wholesale Store in kanchipuram
" />
                <meta name="keywords" content="Sri Sarva lakshmi Silks,Kanchipuram Silk Sarees,Kanchipuram Silk Sarees Manufacturers,Kanchipuram Silk Sarees Manufacturers,Original silk saree shops in kanchipuram , Wholesale Shop,Kanchipuram Silk Sarees Wholesale Shop,kanchipuram Silk Saree Wholesale Store,kanchipuram sarees,Buy sarees online,leading kanchipuram Silk Saree Shop,Best Kanchipuram silk sarees Wholsale Shop,kanchipuram silk sarees wholesalers "></meta>
                </Helmet>
                <Breadcrumb title={'Kanchipuram Silk Sarees Wholesale Enquiry'}/>
                
                
                {/*Forget Password section*/}
             
                {/*logo section end*/}
     <section className=" contact-page section-b-space">
                    <div className="container">
                    <div className="enqury">   <h4>Kanchipuram Silk Sarees Wholesale Enquiry</h4></div>
                      <div className="enqury about_p">  <p>
                      Kanchipuram Sri Sarva lakshmi silk shop Provides silk sarees at unmatched quality with unbeatable price tags. We have wide range of wholesale sarees in kanchipuram for our customers.
                        </p></div>
                        <div className="row section-b-space">
                        
                            <div className="col-lg-12">
                            <iframe  src="https://docs.google.com/forms/d/e/1FAIpQLScFxbNRPM2FXnGZbDzGd08lG6P0ZBlxFDLQPVIRMR0LesLatw/viewform?embedded=true" width="100%" height="940px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                            </div>
                         
                        </div>
                      


                        
                    </div>
                </section>


            </div>
        )
    }
}

export default Enquiry