import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import Custom Components
import Breadcrumb from "../../common/breadcrumb";
import { getFeatureImages} from "../../../services";
import {gift} from '../../../api/portfolio'


class Gift extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          isOpen: false,
          images: gift,
          columns:'col-sm-6',
          title:'Kanchipuram Wholesale Gift Sarees'
        };
    }

    componentWillReceiveProps(nextProps) {
        const {columns} = nextProps.match.params;
       
            let result,title1;
            switch (columns) {
                case '7':   
                title1="Butta Mphoss";
                 break;
                case '6':   
                title1="M.Jaggad";
                 break;
                case '5':   
                title1="Tissue M.wait Jaggad";
                 break;
                case '4':   
                   title1="K.M.D slik";
                    break;
                case '3': 
                title1="Soft Slik";
                    break;
                case '2':  
                title1="Fancy Slik";
                    break;
                    case '1':  
                    title1="Wedding Sarees";
                    break;
                default:      
                    result = "col-sm-6";
                    break;

                 
            }
            result = "col-sm-6";
          
   
    
    }

    selectImage = (index, type) => {
        this.setState({ 
            photoIndex: index,
            isOpen: true,
            images: type === 'all'?gift:getFeatureImages(gift, type) 
        })
    }

    render (){
            
        const { photoIndex, isOpen, images, columns,title } = this.state;
        return (
            <div>
                        <Helmet>
                    <title>Gift Sarees  Sri Sarvalakshmi Silks - Kanchipuram Silk Sarees Manufacturers Wholesale Shop</title>
                    <meta name="description" content="Sri Sarvalakshmi Silk Saree Manufacturers Wholsale Store is One of the Top Best Kanchipuram silk sarees Wholsale Shops in Kanchipuram.Buy sarees online at the leading kanchipuram Silk Saree Wholesale Store in kanchipuram
" />
                <meta name="keywords" content="Sri Sarvalakshmi Silks,Kanchipuram Silk Sarees,Kanchipuram Silk Sarees Manufacturers,Kanchipuram Silk Sarees Manufacturers,Original silk saree shops in kanchipuram , Wholesale Shop,Kanchipuram Silk Sarees Wholesale Shop,kanchipuram Silk Saree Wholesale Store,kanchipuram sarees,Buy sarees online,leading kanchipuram Silk Saree Shop,Best Kanchipuram silk sarees Wholsale Shop,kanchipuram silk sarees wholesalers "></meta>
                </Helmet>
      
                <Breadcrumb title={`${title}`} />

                {/* Our Project Start */}
                <section className="portfolio-section grid-portfolio ratio2_3 portfolio-padding">
                    <div className="container">
                     
                    <div className="col-sm-12">
                            <div className="enqury">   <h4>Kanchipuram Wholesale Gift Sarees</h4></div>
                             <div className="about_p">   <p>Gift sarees are lovingly which can be presented to your loved ones on their special day.Kanchipuram Sri Sarva lakshmi silk shop has a wide range of varieties of Gift Silk Sarees.Gift your family on a wedding day with surprise Gift silk sarees.

                    </p>
                             
                                      </div>
                            </div>
                               
                            
                           
                                <div className="row zoom-gallery">
                                    {gift.map((img, index) => 
                                        <div className={`isotopeSelector filter fashion ${columns}`} key={`all-${index}`}>
                                            <div className="overlay">
                                            <div className="border-portfolio">
                                            <a href="https://photos.app.goo.gl/RlWLhQvGwsjUk4g32" target="_blank" >      <div className="overlay-background" >
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                    
                                                    </div> </a>
                                                    <img src={img.src} className="img-fluid blur-up lazyload bg-img" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                          
                           
                            
                        
                    </div>
                </section>
                
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].src}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}
            </div>
        )
    }
}

export default Gift