import React, { Component } from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';

import {Slider3} from "../../../services/script"

class BlogSection extends Component {
    render (){

        return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...Slider3} className="slide-3 no-arrow ">
                                <div>
                                    <div className="col-md-12">
                                       
                                        <div className="blog-details">
                                            <h4>02 July 2018</h4>
                                          
                                                <p>“I know about this shop very well because I have purchased so many sarees through online...They are very genuine, You can 100% believe this shop for quality.” </p>
                                            <hr className="style1" />
                                                <h6>by: Subashree Pandian</h6>
                                        </div>
                                    </div>
                                </div>
                               
                                <div>
                                    <div className="col-md-12">
                                        
                                    <div className="blog-details">
                                            <h4>10 July 2019</h4>
                                          
                                                <p>“Very prompt in delivery and also silk mart certification given with d saree.  Fully satisfied. Happy to do business with you  , Senthil. Thank u.” </p>
                                            <hr className="style1" />
                                                <h6>by: Urmila Sridhar</h6>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-md-12">
                                  
                                    <div className="blog-details">
                                            <h4>21 September 2019</h4>
                                          
                                                <p>“Very good shop with much more collection. Friendly service. 
Pure silk sarees are available in reasonable cost.” </p>
                                            <hr className="style1" />
                                                <h6>by: Prakash Chandran</h6>
                                        </div>
                                </div>
                                </div>
                                <div>
                                    <div className="col-md-12">
                                       
                                        <div className="blog-details">
                                            <h4>25 January 2020</h4>
                                           <p>“Excellent collection with reasonable prices.
I bought 2 sarees in this shop very pretty much and I loved the sarees.
Very Thankful to you all.” </p>
                                            <hr className="style1" />
                                                <h6>by: Swathi Varalakshmi K </h6>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
        )
    }
}

export default BlogSection;