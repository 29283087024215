import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterOne extends Component {

    componentDidMount(){
      
    }


    render () {

        return (

  
  <footer className="footer-light">
  <div className="light-layout">
     
  </div>
  <section className="section-b-space light-layout">
      <div className="container">
          <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                  <div className="footer-title footer-mobile-title">
                      <h4>about</h4>
                  </div>
                  <div className="footer-contant">
                      <div className="footer-logo">
                          <LogoImage logo="logo.png" />
                      </div>
                      <div className="footer-title sub-title">
                        
                        <h4>Best Silk Sarees Shop</h4>
                    </div>
                      <p>Kanchipuram Sri Sarvalakshmi silk shop Provide Kanchipuram silk sarees with Cheap price tags. We have wide range of wholesale sarees in kanchipuram and  collections of Wedding Silk,Fancy Silk,Semi Silk,Soft Silk and Gift Sarees for our customers </p>
                      <div className="footer-social">
                          <ul>
                              <li>
                              <a href="https://www.facebook.com/kanchipuramsilkshop/" target="_blank" >  <i className="fa fa-facebook" aria-hidden="true"></i></a>
                              </li>
                              
                              {/* <li>
                                  
                              <a href="https://twitter.com" target="_blank"> <i className="fa fa-twitter" aria-hidden="true"></i></a>
                              </li> */}

                              <li>
                              <a href="https://www.youtube.com/watch?v=6muMsp0S0NA&list=PLFv6EYvFtTOJz9orGG8TglmkCTlyZJoBj"  target="_blank">      <i className="fa fa-youtube" aria-hidden="true"></i></a>
                              </li>
                              <li>
                              <a href="https://www.instagram.com/sarvalakshmisilkshop/" target="_blank">      <i className="fa fa-instagram" aria-hidden="true"></i></a>
                              </li>
                              <li>
                              <a href="https://www.pinterest.ca/kanchipuramsilkwholesale/" target="_blank">      <i className="fa fa-pinterest" aria-hidden="true"></i></a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="col offset-xl-1">
                  <div className="sub-title">
                      <div className="footer-title">
                        
                          <h4>Categories</h4>
                      </div>
                      <div className="footer-contant">
                      <ul>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_wedding_sarees`} >Wedding Silk</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_fancy_sarees`} >Fancy Silk</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_soft_sarees`} >Soft Silk</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_semi_silk`} >Semi Silk </Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_gift_sarees`} >Gift Sarees</Link></li>
                                                  
                                                </ul>
                      </div>
                  </div>
              </div>
              <div className="col">
                  <div className="sub-title">
                      <div className="footer-title">
                      <h4>Quick Links</h4>
                      </div>
                      <div className="footer-contant">
                      <ul>
                              <li><a href="#shop">Home</a></li>
                              <li><a href="/pages/about-us">About</a></li>
                              <li><a href="/pages/contact">contacts</a></li>
                              <li><a href="/pages/enquiry">Wholesale Enquiry</a></li>
                              
                          </ul>
                          
                      </div>
                  </div>
              </div>
              <div className="col">
                  <div className="sub-title">
                      <div className="footer-title">
                          <h4>store information</h4>
                      </div>
                      <div className="footer-contant">
                          <ul className="contact-list">
                              <li><i className="fa fa-map-marker"></i>
                              
Sri SarvaLakshmi Silk Sarees<br></br>
                              No. 97, Vilakadi Kovil Street
Kanchipuram - 631501
(Near Kirai Mandabam)
Tamil Nadu, India
                                
                              </li>
                              <li><i className="fa fa-phone"></i><a href="tel:+91 86103 29465">+91 86103 29465</a>
                              <p><i className="fa fa-phone"></i> <a className="tel_a" href="tel:+91 90927 80315">+91 90927 80315</a></p>
                                <p> <i className="fa fa-phone"></i><a className="tel_a" href="tel:+91 9976328080">+91 99763 28080</a></p>
                                <p> <i className="fa fa-phone"></i><a className="tel_a" href="tel:+91 70926 61234">+91 70926 61234</a></p>
                              </li>
                              <li><i className="fa fa-envelope-o"></i>Email Us: <a
                                  href="mailto:kanchipurampattu@gmail.com">kanchipurampattu@gmail.com</a></li>
                      
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <div className="sub-footer ">
      <div className="container">
          <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                  <div className="footer-end">
                      <p><i className="fa fa-copyright" aria-hidden="true"></i> 2020 Sri Sarvalakshmi Silks</p>
                  </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
              <img align="right"  src="/assets/images/handloom.jpg" alt=""></img>
             
                  {/* <div className="row">

                  <div className="col-xl-2 col-md-2 col-sm-2">
                  <img src="/assets/images/fashion/product/66.jpg" alt=""></img>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-2">
                      <img src="/assets/images/fashion/product/66.jpg" alt=""></img>
</div>
<div className="col-xl-2 col-md-2 col-sm-2">
<img src="/assets/images/fashion/product/66.jpg" alt=""></img>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-2">
                      <img src="/assets/images/fashion/product/66.jpg" alt=""></img>
                      </div>
                  </div> */}
                  
                
                
              </div>
              <p className="container">We provide silk sarees shipping in United States| United Kingdom| Malaysia| Canada| United Arab Emirates | Australia | Singapore | Brazil | Sri Lanka | Russia | France | Germany | Saudi Arabia | Kuwait | Switzerland | Qatar | Netherlands | Oman | Bangladesh | China | Norway | South Africa | Japan | Bahrain | Philippines | New Zealand | Denmark | Israel |Ireland | Italy | Sweden | South Korea | Greece | Hong Kong | Indonesia | Portugal | Réunion | Taiwan | Turkey | Brunei | Spain | Romania | Argentina | Chile | Kenya | Mexico | Vietnam | india [ Tamil Nadu | Karnataka | Telangana | Maharashtra | Delhi | Andhra Pradesh | Kerala | West Bengal | Gujarat | Uttar Pradesh | Madhya Pradesh | Puducherry | Odisha | Goa | Haryana | Bihar | Chhattisgarh | Rajasthan | Punjab | Chandigarh | Uttarakhand | Assam | Himachal Pradesh | Jharkhand | Mizoram ]</p>
             
          </div>
      </div>
  </div>
</footer>

  
        
















        )
    }
}

export default FooterOne;