
// Grid Columns Photos

export const photos = [
   
  ]












  export const wedding = [
    {
      src: require('../assets/images/portfolio/grid/wedding_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/wedding_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/wedding_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/wedding_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/wedding_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/wedding_6.jpg'),
      type: 'bags'
    },
    
  ]




  export const soft = [
    {
      src: require('../assets/images/portfolio/grid/soft_silk_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/soft_silk_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/soft_silk_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/soft_silk_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/soft_silk_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/soft_silk_6.jpg'),
      type: 'bags'
    },
    
  ]


  export const semi = [
    {
      src: require('../assets/images/portfolio/grid/semi_silk_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/semi_silk_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/semi_silk_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/semi_silk_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/semi_silk_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/semi_silk_6.jpg'),
      type: 'bags'
    },
    
  ]



  export const gift = [
    {
      src: require('../assets/images/portfolio/grid/gift_saree_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/gift_saree_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/gift_saree_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/gift_saree_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/gift_saree_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/gift_saree_6.jpg'),
      type: 'bags'
    },
    
  ]




  export const fancy = [
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_6.jpg'),
      type: 'bags'
    },
    
  ]



  export const kanchipuram_pure_fancy_silk_sarees = [
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_6.jpg'),
      type: 'bags'
    },
    
  ]

  export const kanchipuram_pure_wedding_silks_sarees = [
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_1.jpg'),
      type: 'fashion'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_2.jpg'),
      type: 'shoes'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_3.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_4.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_5.jpg'),
      type: 'bags'
    },
    {
      src: require('../assets/images/portfolio/grid/fancy_silk_6.jpg'),
      type: 'bags'
    },
    
  ]
  export const butta_mphoss_sarees = [
   
    
  ]

 export const kmd_silksarees = [
   
    
  ]
 export const plain_mphoss_sarees_art_silks_rp = [
   
    
  ]
 export const plain_mphoss_sarees_art_silks_dp = [
   
    
  ]
 export const pure_soft_silks_sarees = [
   
    
  ]
  export const tissue_m_wait_Jaggad_sarees = [
   
    
  ]
    export const jaggad_sarees = [
   
    
  ]




  // Masonary Photos

  export const MasonaryPhotos = [
    {
      src: require('../assets/images/portfolio/1.jpg'),
      type: 'shoes'
    },

    


  ]