import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'

class NavBar extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            navClose: { right: '0px' }
        }
    }

    componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    openNav() {
        console.log('open')
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector("#main-menu").classList.add("hover-unset");
        }
    }

    handleSubmenu = (event) => {
       
     
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else{
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
        //    document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;
            
        if(event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else{
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    render() {
        const { translate } = this.props;
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav" >
                        <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                            <i className="fa fa-bars sidebar-bar"></i>
                        </div>
                        <ul className="nav-menu" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)} >
                                <div className="mobile-back text-right">
                                    <span >Back</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>
                            <li >
                                <a  href='#shop'  className="nav-link" >
                                    {translate('home')}
                                  
                                </a>
                              
                            </li>
                           
                            <li >
                               <a  href='/pages/about-us'  className="nav-link" >
                                    {translate('about_us')}
                                  
                                </a>
                               
                            </li>
                            <li onClick={(e) => this.handleSubmenu(e)}>
                            <a  className="nav-link" >
                                    {translate('sarees')}
                                    <span className="sub-arrow"></span>
                                </a>
                                <ul className="nav-submenu" >
                                    <li><a href={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_wedding_sarees`} >Wedding Silk</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_fancy_sarees`} >Fancy Silk</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_soft_sarees`} >Soft Silk</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_semi_silk`} >Semi Silk</a></li>
                                    <li><a href={`${process.env.PUBLIC_URL}/kanchipuram_wholesale_gift_sarees`} >Gift Sarees</a></li>
                                    {/*  <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/6`} >M.Jaggad</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/7`} >Butta Mphoss</Link></li>
                                   <li><Link to={`${process.env.PUBLIC_URL}/watch`} >{translate('watch')}</Link></li> */}
                                </ul>
                               
                            </li>
                           
                            <li className="mega-menu">
                               <a  href='/pages/kanchipuram_silk_sarees_wholesale_enquiry'  className="nav-link" >
                                    {translate('enquiry')}
                                 
                                </a>
                                
                            </li>
                            
                            <li className="mega-menu">
                               <a  href='/pages/contact'  className="nav-link" >
                                    {translate('contact')}
                                 
                                </a>
                                
                            </li>
                            
                                 
                            
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(NavBar);